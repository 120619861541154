// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Objects (o-)
//
// UI repeatable elements throught the site.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Title
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-title {
  background: $info-color;
  margin-bottom: $grid-column-gutter/2;
  margin-left: -$grid-column-gutter/2;
  margin-right: -$grid-column-gutter/2;
  margin-top: $grid-column-gutter/2;
  padding: $grid-column-gutter/2 0 $grid-column-gutter/4;
  text-shadow: 2px 2px $shadow-color;
  transition: 0.5s;

  @include breakpoint(medium) {
    margin-left: -$grid-column-gutter;
    margin-right: -$grid-column-gutter;
  }

  span {
    color: $warning-color;
  }

  &.o-title--complete {
    background: darken($success-color, 6%);
  }

  &.o-title--top {
    margin-top: -$grid-column-gutter/2;

    @include breakpoint(medium) {
      margin-top: -$grid-column-gutter;
    }
  }
}

.o-title__heading {
  color: $white;
  font-size: rem-calc(30);
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1;
}

.o-title--pricing {
  margin-bottom: $grid-column-gutter/2;
  padding-top: $grid-column-gutter;
}

.o-title--pricing__heading {
  color: $info-color;
  font-size: rem-calc(36);
  font-weight: bold;
  line-height: 1;
}

.o-title--pricing__subheading {
  color: $emphasis-color;
  font-size: rem-calc(18);
}

.o-title--pricing__table {
  border: 0;
  margin-bottom: 0;
  width: 100%;
}

// Band Style
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
input {
  &[name='bandStyle'] {
    margin-top: $grid-column-gutter/2;
  }
}

// Overflow Container
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-container {
  max-height: rem-calc(560);
  overflow: auto;
  padding: 0;

  @include breakpoint(medium) {
    padding: 0 ($grid-column-gutter / 2);
  }
}

.o-container--short {
  max-height: 21rem;
  overflow: auto;
}

// Box
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-box {
  background: $white;
  border: 1px dashed $iron;
  margin-bottom: 1rem;
  padding: rem-calc(10);
  position: relative;
  text-align: center;

  &:hover:not(.no-hover) {
    background: lighten($info-color, 40%);
    border-color: $info-color;
    cursor: pointer;
  }

  &.js-active {
    background: lighten($success-color, 50%);
    border-color: $success-color;
  }

  input {
    margin-bottom: 0;
  }

}

.o-box__input {
  margin-right: rem-calc(7);
}

.o-box--small {

  @extend .o-box;
}

.o-box--bandnav {

  @extend .o-box;
  min-height: rem-calc(90);
  text-align: left;

  &.js-active {
    background: lighten($success-color, 50%);
    border-color: $success-color;
  }
}

.o-box__emphasis {
  color: $black;
  font-size: rem-calc(12);
  font-style: italic;
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 7px;

  @include breakpoint(medium) {
    font-size: rem-calc(14);
  }

  span {
    background: $yellow;
    padding: 2px 4px;

    @include breakpoint(medium) {
      padding: 5px 10px;
    }
  }

  &:after {
    border-color: $yellow transparent transparent;
    border-style: solid;
    border-width: 15px 15px 0 0;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 10px;
    transform: rotate(360deg);
    width: 0;

    @include breakpoint(small only) {
      top: 19px;
    }
  }

  &.second__emphasis {
    margin-top: 26px;

    @include breakpoint(small only) {
      margin-top: 18px;
    }

    span {
      border-top: 1px dashed $black;
    }
  }
}

.js-bandType {

  img {
    margin-bottom: rem-calc(8);
  }
}

.color-box {

  &:hover:not(.no-hover) {
    cursor: default;
  }
}

// Horizontal scrolling for section 3
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//used both selectors to make sure it has enough points to overrride
.row.horiz__row {


  @include breakpoint(small only) {
    display: table-row;

    .column:first-child {
      padding-left: 0;
    }
  }
}


.column.horiz__cell {
  .small-up-2 > & {
    @include breakpoint(small only) {
      display: table-cell;
      float: none;
      width: auto;
    }
  }
}

.horiz__width {
  @include breakpoint(small only) {
    width: 200px;
  }
}



// Scroll Indicator
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

%scroll-indicator {
  bottom: 0;
  height: 60%;
  position: absolute;
  width: 20px;

  @include breakpoint(400px) {
    height: 65%;
  }

  @include breakpoint(medium) {
    display: none;
  }

  &::after {
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: relative;
    width: 100%;
    z-index: 2;
  }
}

.scroll-indicator--right {
  @extend %scroll-indicator;
  right: 0;

  &::after {
    background-image: radial-gradient(at 100% 50%, rgba($black, 0.5) 0%, rgba($black, 0.08) 58%, transparent 74%);
    border-right: 1px solid rgba($black, 0.12);
  }
}

.scroll-indicator--left {
  @extend %scroll-indicator;
  left: 0;

  &::after {
    background-image: radial-gradient(at 0% 50%, rgba($black, 0.5) 0%, rgba($black, 0.08) 58%, transparent 74%);
    border-left: 1px solid rgba($black, 0.12);
  }
}



// Clipart
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.clipartPosition {
  .button {
    margin-bottom: 5px;
  }
}

.o-clipart {
  color: $body-font-color;
  display: inline-block;
  margin-right: rem-calc(10);

  &:hover {
    color: $info-color;
    cursor: pointer;
  }

  img {
    height: 30px;
    transform-style: preserve-3d;
    transition: 0.6s;
    width: auto;
  }
}

.o-clipart__img {
  height: 50px;
  width: auto;
}

.o-preview__clipart {
  svg {
    transform-style: preserve-3d;
    transition: 0.6s;
  }
}

.js-flipped {
  filter: FlipH;
  transform: scaleX(-1);

  svg {
    filter: FlipH;
    transform: scaleX(-1);
  }
}

.o-box__heading {
  font-size: rem-calc(24);
  line-height: 1.2;
  margin: 0;
  min-height: 56px;
}

.o-box__subheading {
  line-height: 1;
  min-height: 43px;
}

.o-box__button {
  margin-bottom: 0;
}

// Used for band color title (ex: "black")
.o-box__title {
  font-weight: bold;
  line-height: 1;
  margin-top: rem-calc(20);
}

.o-box__name {
  font-weight: bold;
}

.o-box__image {
  box-shadow: 0 0 5px 0 rgba($aluminum, 0.50);
  max-width: 100%;
  width: 244px;
}

.o-box__image--small {
  float: left;
  height: rem-calc(60);
  margin-right: rem-calc(10);
  width: auto;
}

.o-box__qty,
.o-box__qty--adult,
.o-box__qty--youth {
  font-size: rem-calc(14);
}

.clipart-button {
  margin: 0 0.5rem 0.5rem 0;
}


// Wristband
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-preview {
  margin: $grid-column-gutter auto;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: auto;
  word-break: keep-all;

  @include breakpoint(small) {
    width: 325px;
  }

  @include breakpoint(medium) {
    width: 610px;
  }

  @include breakpoint(large) {
    width: auto;
  }

  .preview__product__Tyvek {
    height: 65px;
    width: 880px;

    .o-preview__message {
      line-height: 65px;
    }
  }

  &.js-zoom-in {
    overflow: hidden;
    width: 980px;
  }
}

.o-preview__side {
  background: $black;
  border: 1px solid $aluminum;
  box-shadow: 3px 3px 9px $aluminum;
  height: 53px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  transform: scale(0.33);
  transform-origin: 0% 65%;
  transition: transform 0.25s ease;
  width: 950px;

  @include breakpoint(medium) {
    margin: 0 auto;
    transform: scale(0.62);
  }

  @include breakpoint(large) {
    margin: $grid-column-gutter auto;
    transform: scale(1);
  }
}

.o-preview__message {
  color: $white;
  font-size: rem-calc(24);
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 50px;
  position: relative;
  vertical-align: middle;
  z-index: 10;
}

.o-preview__message--no-text-color {
  color: $white;
  opacity: 0.3;
  text-shadow: -1px -1px 1px $white, 1px 1px 1px #000;
}

.o-preview__clipart {
  & svg {
    height: 28px;
    margin-top: 4px;
    max-width: 50px;
    vertical-align: sub;
    width: auto;
  }

  img {
    height: 30px;
    transform-style: preserve-3d;
    transition: 0.6s;
    width: auto;
  }
}

.svgPreview {
  fill: $white;
  height: 30px;
  width: auto;
}

.o-preview__message--debossed {
  text-shadow: -1px -1px 1px $white, 1px 1px 1px $black;
}

.o-preview__message--embossed {
  text-shadow: -1px -1px 1px $white, 1px 1px 1px $black;
}

.o-preview__message--inside {
  @extend .o-preview__message;
  color: $white;
  opacity: 0.9;
  text-shadow: -1px -1px 1px $white, 1px 1px 1px $black;
}

.bandPreviewSwirled {
  filter: blur(10px);
  height: 110%;
  left: -5%;
  position: absolute;
  top: -5%;
  width: 110%;
  z-index: 0;
}

.bandPreviwSwirledFirstColor {
  fill: $success-color;
}

.bandPreviewSwirledSecondColor {
  fill: $white;
}

// Don't show text colors in preview nav for color coat
#bandColorPreview.PreviewColorCoat .bandColorPreviewTextColor {
  display: none;
}

//Band Preview Zoomer (shows for medium and smaller only)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-preview__zoomer {
  position: relative;
}

.o-preview__zoomer--buttons {
  bottom: 10px;
  position: absolute;
  right: 10px;
}

.o-preview__zoomer--box {
  background: $vapor;
  border: 1px solid $medium-gray;
  border-radius: 3px;
  height: 135px;
  overflow: scroll;
  padding: 15px;
  user-select: none;

  @include breakpoint(medium) {
    height: auto;
    margin-bottom: 1rem;
  }
}

.o-preview__zoomer--button {
  background: $black;
  border: 3px solid $white;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: rem-calc(15);
  line-height: 1;
  margin: 0 0 0 5px;
  opacity: 0.3;
  padding: 5px;
  transition: all 0.25s ease;
  vertical-align: middle;

  &:hover {
    opacity: 0.5;
  }
}

.js-zoom-in {
  .o-preview__side {
    transform: scale(0.8);

    @include breakpoint(medium) {
      transform: scale(1);
    }
  }
}

// Band Navigation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-bandnav {
  display: inline-block;
}

.o-bandnav__item {
  display: inline-block;
  margin: 0 10px 10px 0;
  width: 100%;

  @include breakpoint(medium) {
    width: 250px;
  }
}

.o-bandnav__img {
  display: block;
  float: right;
  height: rem-calc(60);
  margin-bottom: rem-calc(5);
  width: auto;
}

.o-bandnav__title {
  font-size: rem-calc(18);
  font-weight: bold;

  &.js-active {
    color: $success-color;
  }
}

.o-bandnav__meta {
  font-size: rem-calc(14);
  font-style: italic;
  text-transform: capitalize;
}

.o-bandnav__promo {
  color: $success-color;
  font-weight: bold;
}

.o-bandnav__amount {
  font-size: rem-calc(14);
  line-height: 1;
}

.o-bandnav__textcolor {
  border: 1px solid $iron;
  content: '';
  height: rem-calc(10);
  margin-left: rem-calc(5);
  padding: 0 rem-calc(14*2);
  width: rem-calc(14*2);
}

// Band Preview Option
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-previewoptions {
  display: inline;
  list-style-type: none;
}

.o-previewoption {
  display: inline-block;
  margin-right: rem-calc(25);
  padding: rem-calc(10) rem-calc(10);
  text-align: left;
}

input[type=radio].o-previewoption__radio {
  float: left;
  margin-right: rem-calc(10);
  margin-top: rem-calc(20);
}

.o-previewoption__band {
  background: #1968E7;
  box-shadow: 3px 3px 9px #999;
  color: $white;
  display: inline-block;
  padding: rem-calc(4) rem-calc(8);
}

.o-previewoption__text {
  font-size: rem-calc(18);
}

.o-previewoption__quantity {
  font-size: rem-calc(12);
  font-style: italic;
}

.o-previewoption__fontsizecontainer {
  display: table;
}

.o-previewoption__fontsize {
  display: table-cell;
  font-size: rem-calc(20);
  height: 38px;
}

#bandFontSizeIncrease {
  display: inline;
  font-size: rem-calc(30);
  margin-left: rem-calc(10);
}

.o-errorinput {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: $red;
}

// Total
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-total {
  // font-size: ;
}

.o-total__amount {
  font-size: rem-calc(24);
  line-height: 2;
  // font-weight: bold;
  span {
    color: $purple;

    .o-total__promo {
      color: $success-color;
      font-style: italic;
    }
  }
}

.o-total__button {
  margin-bottom: 0;
  margin-top: rem-calc(20);
}

.o-total__link {
  font-size: rem-calc(16);
  font-style: italic;
  margin-bottom: rem-calc(20);
  margin-left: rem-calc(5);
}

// Forms
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-label--increase {
  color: $alert-color;
  font-size: rem-calc(12);
  font-style: italic;
}

.o-disclaimer {
  color: $aluminum;
  font-size: rem-calc(14);
  font-style: italic;
}

// Credit Cards
.o-cc {
  margin-left: 0;

  li {
    display: inline-block;
    float: none;
    margin-left: rem-calc(8);
  }

  i {
    font-size: rem-calc(36);
  }
}

// Alert
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-alert {
  background: #f2fee7;
  border: 3px solid #d7eac5;
  font-size: 1rem;
  padding: ($grid-column-gutter / 2) $grid-column-gutter;
}

.o-alert--warning {
  background: lighten($yellow, 20%);
  border-color: $yellow;

  .o-alert__title {
    color: $black;
    margin-bottom: 14px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.o-alert__title {
  font-size: rem-calc(18);
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0;
}

.o-alert__desc {
  line-height: 1;
  margin: 0;
  margin-top: 8px;
}

// Options
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-option__title {
  font-size: rem-calc(20);
  line-height: 1.2;
}

.o-option__title--increase {
  color: $alert-color;
  font-style: italic;
}

.o-option__img {
  // max-width: 90%;
}

// Table
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-table {
  width: 100%;

  td {
    font-size: rem-calc(20);
    padding: rem-calc(12) rem-calc(14) rem-calc(14);
  }
}

.o-table__promo td {
  color: $success-color;
  font-style: italic;
}



// Radio Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-button-group {
  margin-bottom: rem-calc(15);

  @include breakpoint(medium down) {
    margin-left: 0;
  }

  .button {
    // TODO: Try and improve without !important
    border-radius: 0 !important;

    @include breakpoint(medium down) {
      padding: 4px;
    }
  }
}



// Radio Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.o-info {
  border: 1px solid #ccc;
  margin-left: -1px;
  margin-top: 15px;
  padding: 20px;
}

.o-info__box {
  color: $aluminum;
  font-size: rem-calc(14);
}

#purchaseOrderInfo
{
  overflow:hidden;
  height:725px;
}
