// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Product - Seen on the homepage
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.product {
  text-align: center;
}

.product__box {
  border: 1px solid #ccc;
  margin-left: -1px;
  margin-top: -1px;
  padding: 20px;
}

.product__heading {
  font-size: rem-calc(20);
}

.product__subheading {
  font-weight: bold;
}

.product__img {}

.product__price {
  font-style: italic;
  margin-bottom: 10px;
}

.product__button {
  margin-bottom: 0;
}
