// scss-lint:disable SelectorFormat ImportantRule PlaceholderInExtend QualifyingElement

// Menu
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.title-bar {
  background: $h-main-menu-bg;
  background-color: $emphasis-color;
  border-top: 3px solid $h-main-menu-active;
}

.top-bar {
  padding: 0;
}

.menu {
  z-index: 9999;

  li {
    @include breakpoint(small only) {
      display: block;
      float: left;
      width: 100%;
    }

    a {
      color: $white;
      font-weight: 700;
      padding: 16px 37px;
      text-transform: capitalize;

    }

    a:hover {
      background: darken($h-main-menu-bg, 10%);
    }

    &.is-down-arrow > a {
      padding-right: 37px;
    }

    &.is-down-arrow > a::after {
      padding-right: 0;
      top: 1.1rem;
    }

    .menu_active {
      background: darken($h-main-menu-bg, 10%);
      border-top: 3px solid $h-main-menu-active;
    }
  }
}

ol {
  counter-reset: list;

  li {
    list-style: none;

    h3:before {
      content: counter(list) '.)\0020';
      color:inherit;
      font-size:inherit;
      counter-increment: list;
      display:inline-block;
    }
  }
}

// Modal
.reveal-modal {
  outline: none;

  .close-reveal-modal {
    top: 12px;
  }
}

body.is-reveal-open {
  @include breakpoint(small only) {
    overflow: auto;
  }
}

// Dropdown
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.f-dropdown {
  background: $white;
  height: 300px !important;
  left: inherit !important;
  list-style-type: none;
  margin: .5rem 0 0;
  overflow-x: scroll;
  top: inherit !important;
  width: 190px !important;
}

.f-dropdown__link {
  border: solid 1px #ccc;
  display: block;
  line-height: 40px;
  padding: 3px 5px;
  padding-left: 1rem;
  position: relative;
  text-align: left;
  width: 100%;
}

.f-dropdown__arrow {
  color: $oil;
  font-size: 10px;
  padding: 2px 0;
  position: absolute;
  right: 2px;
  top: 4px;
}

.f-dropdown--fonts {
  // outline: none;
  // overflow: scroll;

  img {
    padding: 3px;

    &:hover {
      opacity: .5;
    }
  }
}

.is-dropdown-submenu {
  min-width: 230px;
}

// Make range inputs have a certain height
[type=range] {
  height: 40px;
}


// Center Block Grids with .text-center
[class*='block-grid-'] {
  &.text-center {
    > li {
      display: inline-block;
      float: none;
    }
  }
}

.firstModalTitle {
  background: $info-color;
  color: $white;
  font-size: 26px;
  font-weight: bold;
  margin: -$reveal-padding;
  margin-bottom: $reveal-padding;
  padding: $reveal-padding/2 $reveal-padding;

  @include breakpoint(medium) {
    font-size: 36px;
  }
}

.row--full {
  @extend .row;
  max-width: none;
}

// Add styles to Foundation's default button styles.
.button {
  border-radius: $global-radius;
  box-shadow: 0 -3px 0 rgba(0, 0, 0, .2) inset;
  outline: none;
}

.secondary.button {
  color: $black;
}

input[type=checkbox],
input[type=radio] {
  margin-bottom: 0;
  margin-right: rem-calc(7);
}

.pinned {
  background: $white;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 20%;
}

div.table-wrapper div.scrollable {
  margin-left: 20%;
}

.error.info {
  background: $info-color;
  display: block;
}

.js-bandType label {
  font-size: rem-calc(12);
  margin-left: 3px;
  text-align: left;
}



// Spectrum - Color Picker
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.sp-replacer {
  background: $white;
  border-color: $aluminum;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  height: 46px;
  width: 140px;
}

.sp-preview {
  height: 100%;
  width: 114px;
}

.sp-dd {
  line-height: 30px;
}

.sp-preview-inner {
  // width: 50px;
}

.sp-container button.sp-palette-toggle,
.sp-container button.sp-choose {
  @include button(false, $info-color, darken($info-color, 10%), $white, solid);

  background-image: none;
  font-size: map-get($button-sizes, tiny);
  margin: 0;
  margin-top: 3px;

  &,
  &:hover {
    border: 0 !important;
    text-shadow: none !important;
  }
}

input[type='text'].sp-input {
  margin-bottom: 2px;
}


// Disabled Anchor Tags
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.js-disabled {
  color: #ccc;

  &:hover,
  &:visited,
  &:active {
    color: #ccc;
    cursor: not-allowed;
  }
}

.fa-check {
  color: $success-color;
}

.hide {
  display: none !important;
}


// Vertical Tabs
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@media only screen and (min-width: 40.063em) {
  .tabs.vertical {
    max-width: 100%;
    width: 100%;
  }
}


// Accordion Margin
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

ul.accordion {
  margin-left: 0 !important;
}



// Mobile Preview
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

#mobilePreview.reveal-modal.open {
  @include breakpoint(portrait) {
    left: -19vh;
    min-height: inherit;
    min-width: 95vh;
    position: fixed;
    top: 25vh !important;
    transform: rotate(90deg);
  }
}

#mobilePreview.reveal-modal.open {
  @include breakpoint(landscape) {
    height: 96% !important;
    margin: 1% !important;
    position: fixed;
    top: 0 !important;
    width: 98% !important;
  }
}



// Slider
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

rzslider .rz-pointer {
  background-color: $info-color;
}

rzslider .rz-pointer.rz-active:after {
  background-color: darken($info-color, 10%);
}

// Form Error
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.form-error--info {
  color: $info-color;

  &:not(.hg-hide) {
    display: block;
  }
}

// Promo
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.cart span.promo,
.promo {
  color: $success-color;
  font-style: italic;
}

// Carousel
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.slick-loading .slick-list
{
  background: #fff url('../images/ajax-loader.gif') center center no-repeat !important;
}

.is-dropdown-submenu-parent:not(.is-active) a:after {
  border: 5px inset !important;
  border-color: transparent transparent transparent #e7832c !important;
  border-left-style: solid !important;
  border-right-width: 0 !important;
}

// Captcha
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.grecaptcha-badge {
  visibility: hidden;
}
