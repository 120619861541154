// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # Footer (f-)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// Description:
// The bottom of the website.
//
// 0. Footer
// 1. Menu
// 2. Gallery
// 3. Contact
// 4. Copyright
// 5. Social
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


// Footer
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$footer-background-top: rgba(0, 50, 99, 1);
$footer-background-bottom: rgba(0, 23, 48, 1);

.f-footer {
  background: linear-gradient(to bottom, $footer-background-top 0%, $footer-background-top 60%, $footer-background-bottom 100%);
  margin-top: $grid-column-gutter;
  padding: rem-calc(50) 0 rem-calc(70);

  a {
    &:not(.button) {
      color: $medium-gray;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    color: $medium-gray;
  }

  .button {
    margin-bottom: rem-calc(5);
  }

  .fa {
    color: $lightest-blue;
  }
}

.f-footer__title {
  color: $medium-gray;
  font-size: rem-calc(24);
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: rem-calc(18);
}



// Menu
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.f-menu {
  margin: 0;
  padding: 0;

  &.side-nav {
    li {
      a:not(.button) {
        color: $white;
      }
    }
  }
}



// Contact
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.f-contact {
  align-items: flex-end;
  background: darken($f-footer-bg, 10%);
  border-top: 10px solid $f-footer-bg;
  display: flex;
  margin-bottom: rem-calc(30);
  min-height: 87px;
}

.f-contact__img {
  float: left;
  margin-right: rem-calc(20);
  margin-top: -10px;
}

// .f-contact__text {}

.f-contact__text-question {
  font-size: rem-calc(20);
  font-weight: bold;
  letter-spacing: 1px;
  line-height: rem-calc(20);
  margin-top: rem-calc(5);
}

.f-contact__text-sub {
  color: $white;
  line-height: 1;
  margin-bottom: rem-calc(5);
}


// Copyright
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.f-copyright {
  background: darken($f-footer-bg, 10%);
  padding: rem-calc(10);

  @include breakpoint(small only) {
    font-size: rem-calc(5);
  }
}

.f-copyright__colophon {
  color: $white;
  & a { color: $white; }
}

.f-social {
  .fa:hover {
    color: $info-color;
  }
}

.f-social__list {
  font-size: rem-calc(40);
  margin-bottom: 0;
}

.f-social__title {
  color: $iron;
  font-weight: bold;
}

// Ratings
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.shopper__approved a {
  color: $white;
  text-transform: capitalize;
}

// Carousel
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.carousel__image__wrapper {
  background: $white;
  border-radius: 6px;
  display: inline-grid;
  height: 200px;
  margin: 10px;
  padding: 10px;
  width: 90%;
  overflow: auto;

  .carousel__image {
    -moz-filter: grayscale(100);
    -o-filter: grayscale(100);
    -webkit-filter: grayscale(100);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); // Firefox 3.5+
    filter: grayscale(100); // IE6-9
    margin: auto;
    max-height: 90%;
    overflow: auto;
  }

  .carousel__image:hover {
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -webkit-filter: grayscale(0);
    filter: none;
  }
}

.slick-track {
  .carousel__client__images {
    height: auto;
  }
}

.f-footer .slick-next,
.f-footer .slick-prev {

  @include breakpoint(small only) {
    margin: 0 auto;
    padding: 25px;
    position: sticky;
  }
}
