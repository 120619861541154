// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Utilities
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Display
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.u-small-block {
  @include breakpoint(small only) {
    display: block;
  }
}

// Spacing
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.u-small-margin-bottom {
  @include breakpoint(small only) {
    margin-bottom: 2rem;
  }
}

.u-whitespace {
  white-space: pre;
}

/* TADA */
@keyframes tada {
  0% {transform: scale(1)}
  10%,20% {transform:scale(.9) rotate(-8deg);}
  30%,50%,70% {transform:scale(1.3) rotate(8deg)}
  40%,60% {transform:scale(1.3) rotate(-8deg)}
  80%,100% {transform:scale(1) rotate(0)}
}

.faa-tada.animated,
.faa-tada.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-tada {
  animation: tada 2s linear infinite;
}
.faa-tada.animated.faa-fast,
.faa-tada.animated-hover.faa-fast:hover,
.faa-parent.animated-hover:hover > .faa-tada.faa-fast {
  animation: tada 1s linear infinite;
}
.faa-tada.animated.faa-slow,
.faa-tada.animated-hover.faa-slow:hover,
.faa-parent.animated-hover:hover > .faa-tada.faa-slow {
  animation: tada 3s linear infinite;
}
