// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Foundation Icon Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -





// Settings
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// The CSS prefix for the class names
// @type {string}
$ffab-css-prefix: 'ffab' !default;

// The angle of the button's icon overlay
// @type {integer} for degrees
$ffab-angle: 15 !default;

// The speed of the :hover animation
// @type {seconds}
$ffab-animation-speed: .3s !default;

// Defines the behavior of the :hover animation
// @type {string}
// @options 'remove' or 'cover' or 'none'
$ffab-animation-behavior: 'remove' !default;

// Defines the background of the icon overlay
// @type {color}
$ffab-overlay: rgba(255, 255, 255, .1);

// Determins if there is a button shadow
// @type {integer}
$ffab-has-shadow: true !default;

// The box-shadows properties of a `true` has shadow button
// Set to none if you don't want the shadow;
// @type {box-shadow value}
$ffab-shadow-color: rgba(0, 0, 0, .1) !default;

// Defines the size (height) of the box shadow
// This number is divided by the button font size
// (ex: $size / $ffab-shadow-size)
// @type {integer}
$ffab-shadow-size: 10 !default;





// Functions
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function ffab-add($first, $second) {
  @return strip-units($first) + strip-units($second) + rem;
}

@function ffab-width($size) {
  @return strip-units(rem-calc($ffab-angle)) + strip-units(map-get($button-sizes, $size) * 3) + rem;
}



// Mixins
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Utilities
// - - - - -

@mixin ffab-u-animate {
  .#{$ffab-css-prefix}-spin::before {
    @extend .fa-spin;
  }

  .#{$ffab-css-prefix}-pulse::before {
    @extend .fa-pulse;
  }
}

@mixin ffab-u-transform {
  .#{$ffab-css-prefix}-rotate-90::before {
    @extend .fa-rotate-90;
  }

  .#{$ffab-css-prefix}-rotate-180::before {
    @extend .fa-rotate-180;
  }

  .#{$ffab-css-prefix}-rotate-270::before {
    @extend .fa-rotate-270;
  }
}

@mixin ffab-u-flip {
  .#{$ffab-css-prefix}-flip-horizontal::before {
    @extend .fa-flip-horizontal;
  }

  .#{$ffab-css-prefix}-flip-vertical::before {
    @extend .fa-flip-vertical;
  }
}

@mixin ffab-utilities {
  @include ffab-u-animate;
  @include ffab-u-transform;
  @include ffab-u-flip;
}



// Button Styles
// - - - - - - -

@mixin ffab-base {
  overflow: hidden;
  position: relative;

  &::before {
    @include fa-icon;
    line-height: 1;
    position: absolute;
    z-index: 2;
  }
}

@mixin ffab-overlay {
  &:after {
    background: $ffab-overlay;
    content: '';
    height: 110%;
    position: absolute;
    top: -5%;
    transition: all $ffab-animation-speed;
    width: ffab-width(default);
    z-index: 1;
  }
}

@mixin ffab-shadow($size) {
  @if $ffab-has-shadow {
    box-shadow: 0 ((strip-units($size) / $ffab-shadow-size) * -1)+rem 0 $ffab-shadow-color inset;
  }
}

// @mixin ffab-before {}

// @mixin ffab-after {}




// Classes
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin foundation-font-awesome-buttons {

  @include ffab-utilities;

  button,
  .button {

    &.#{$ffab-css-prefix}-before,
    &.#{$ffab-css-prefix}-after {
      @include ffab-base;
      @include ffab-overlay;

      @each $size, $value in $button-sizes {
        @if $size == default {
          @include ffab-shadow($value);
          &::after {
            width: ffab-width(#{$size});
          }
        } @else {
          &.#{$size} {
            @include ffab-shadow($value);
          }
          &.#{$size}::after {
            width: ffab-width(#{$size});
          }
        }
      }

      &:hover:not(.disabled) {
        &:after {
          @if $ffab-animation-behavior == 'cover' {
            transform: skewX(0deg) !important;
            width: 100% !important;
          } @else if $ffab-animation-behavior == 'remove' {
            transform: skewX(0deg) !important;
            width: 0% !important;
          } @else {

          }
        }
      }
    }

    &.#{$ffab-css-prefix}-before {

      @each $size, $value in $button-sizes {
        @if $size == default {
          padding-left: ffab-width(#{$size});
        } @else {
          &.#{$size} {
            padding-left: ffab-width(#{$size});
          }
        }
      }

      &:before { left: get-side($button-padding, left); }

      &:after {
        left: -#{$ffab-angle}px;
        transform: skewX(#{$ffab-angle}deg);
        transform-origin: left;
      }
    }

    &.#{$ffab-css-prefix}-after {

      @each $size, $value in $button-sizes {
        @if $size == default {
          padding-right: ffab-width(#{$size});
        } @else {
          &.#{$size} {
            padding-right: ffab-width(#{$size});
          }
        }
      }

      &:before {
        right: get-side($button-padding, right);
      }

      &:after {
        right: -#{$ffab-angle}px;
        transform: skewX(-#{$ffab-angle}deg);
        transform-origin: right;
      }
    }

  }
}
