// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Date
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.date {
  border-radius: $global-radius*3;
  box-shadow: 0 1px 0 $base, 0 2px 0 $white, 0 3px 0 $base, 0 4px 0 $white, 0 5px 0 $base, 0 0 0 1px $base;
  display: block;
  margin-bottom: 1rem;
  text-align: center;

  &.js-active {
    .date__month {
      background: $success-color;
      border-color: darken($success-color, 10%);
    }

    .date__date { border-color: $success-color; }
    .date__day { color: $success-color; }
  }
}

.date__label {

  &:hover {
    cursor: pointer;
  }
}

.date__month {
  background: $primary-color;
  border-bottom: 2px dashed darken($primary-color, 10%);
  border-radius: $global-radius*3 $global-radius*3 0 0;
  color: $white;
  font-size: rem-calc(16);
  font-weight: bold;
  padding: rem-calc(5) 0;
}

.date__date {
  border-top: 2px solid $primary-color;
  font-size: 2.8rem;
  font-weight: bold;
  letter-spacing: -.05rem;
  line-height: 1;
  padding-top: rem-calc(10);
}

.date__name { font-weight: bold; }

.date__day {
  color: $primary-color;
  font-size: rem-calc(18);
  font-weight: bold;
  line-height: 1;
}

.date__price {
  line-height: 2;
}
.no-delivery-date {
  position: relative;
  &::after {
    background: $no-delivery;
    border-radius: $global-radius * 3;
    color: lighten($red, 20%);
    content: 'delivery date not available';
    cursor: not-allowed;
    font-size: rem-calc(18);
    font-style: italic;
    height: 100%;
    left: 0;
    line-height: 1.1;
    padding: 50px 10px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
}
