// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Title
//
// UI element displaying a title (header)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.title {
  background: $info-color;
  margin-bottom: $grid-column-gutter/2;
  margin-left: -$grid-column-gutter/2;
  margin-right: -$grid-column-gutter/2;
  margin-top: $grid-column-gutter/2;
  padding: $grid-column-gutter/2 0 $grid-column-gutter/4;
  text-shadow: 2px 2px rgba($black, .3);
  transition: .5s;

  @include breakpoint(medium) {
    margin-left: -$grid-column-gutter;
    margin-right: -$grid-column-gutter;
  }

  span {
    color: $warning-color;
  }

  &.title--complete {
    background: darken($success-color, 6%);
  }

  &.title--inner {
    margin-left: -$grid-column-gutter;
    margin-right: -$grid-column-gutter;
    @include breakpoint(large) {
      margin-left: -($grid-column-gutter + $grid-column-gutter/2);
      margin-right: -($grid-column-gutter/2);
    }
  }

  &.title--aside {
    background: $purple;
    margin-left: -$grid-column-gutter;
    margin-right: -$grid-column-gutter;
    @include breakpoint(large) {
      margin-left: -$grid-column-gutter/2;
      margin-right: -($grid-column-gutter + $grid-column-gutter/2);
    }
  }

  &.title--top {
    margin-top: -$grid-column-gutter/2;
    @include breakpoint(medium) {
      margin-top: -$grid-column-gutter;
    }

    &.title--inner {
      @include breakpoint(small only) {
        margin-top: 0;
      }
    }
  }
}

.title__heading {
  color: $white;
  font-size: rem-calc(20);
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1;

  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }
}

.title--pricing {
  margin-bottom: $grid-column-gutter/2;
  padding-top: $grid-column-gutter;
}

.title--pricing__heading {
  color: $info-color;
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1;

  @include breakpoint(medium) {
    font-size: rem-calc(36);
  }
}

.title--pricing__subheading {
  color: $emphasis-color;
  font-size: rem-calc(18);
}

.title--pricing__table {
  border: 0;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
  width: 100%;
}
