// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Notification (o-)
//
// Notification for current price of working order.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


.o-notification {
  background: $white;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
  display: none;
  padding: $grid-column-gutter/2;
  position: fixed;
  width: 200px;
  z-index: 1000;

  @include breakpoint(medium) {
    display: block;
  }

  @media (max-width: 1649px) {
    bottom: 0;
    transition: bottom 0.5s;

    &.o-notification--collapsed {
      bottom: -96px;
    }
  }

  @media (min-width:1650px) {
    box-shadow: none;
    margin-left: 1170px;
    margin-top: -30px;
  }
}

.o-notification__button {
  margin-bottom: 0;
}

.o-notification__arrow {
  @extend .fa;
  @extend .fa-angle-double-down;
  float: right;
  margin-top: 4px;
  transition: transform 0.5s;

  @media (min-width: 1400px) {
    display: none;
  }

  .o-notification--collapsed & {
    transform: rotate(180deg);
  }
}

.o-notification__emphasis {
  font-style: italic;
  color: $success-color;
  font-size: 12px;
}

.o-notification__title {
  background: $purple;
  color: $white;
  font-size: 1rem;
  font-weight: bold;
  margin: -($grid-column-gutter/2);
  padding: $grid-column-gutter/3;
  text-shadow: 2px 2px rgba(0,0,0,.2);

  .o-notification--emphasis & {
    background: $success-color;
  }

  @media (max-width: 1399px) {
    cursor: pointer;
  }
}
