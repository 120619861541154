// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Forms
// http://codepen.io/rstrahl/pen/yyEYBx
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


input[type=radio].radio__input,
input[type=checkbox].checkbox__input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.radio__title:before {
  content: '\f10c';
  display: inline-block;
  font-family: FontAwesome;
  letter-spacing: 10px;
  width: 1.4em;
}

.checkbox__title:before {
  content: '\f096';
  display: inline-block;
  font-family: FontAwesome;
  letter-spacing: 10px;
  width: 1.4em;
}

input[type=radio].radio__input:checked ~ .radio__title:before {
  color: $success-color;
  content: '\f05d';
  letter-spacing: 10px;
}

input[type=radio].radio__input:checked ~ .radio__title {
  color: $success-color;
}

input[type=checkbox].checkbox__input:checked ~ .checkbox__title:before {
  content: '\f046';
  letter-spacing: 8px;
}



// Custom Styles
#orderStep1 .radio {
  font-size: rem-calc(14);
  margin-top: rem-calc(5);
  @include breakpoint(medium) {
    font-size: rem-calc(20);
    margin-top: rem-calc(10);
  }
}

.radio--info {
  & .radio input[type=radio].radio__input:checked ~ .radio__title:before {
    color: $info-color;
  }
  & .radio input[type=radio].radio__input:checked ~ .radio__title {
    color: $info-color;
  }
}

.radio--inline .radio {
  float: left;
}

.radio--button .radio input[type=radio].radio__input:checked ~ .radio__title:before {
  color: $white;
}

.radio--button .radio input[type=radio].radio__input:checked ~ .radio__title {
  color: $white;
}

.disabled-label {
  opacity: 0.4;
}

.free-sample-form {
  input:not([type="email"]) {
    text-transform: capitalize;
  }
}
