@mixin right-side-gradient($width) {
  position: relative;

  &::after {
    background: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 0) 25%, rgba($dark-gray, 0.75) 100%);
    content: '';
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8f8f8f', endColorstr='#595959', GradientType=1);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    z-index: 10;

    @include breakpoint($width) {
      display: none;
    }
  }
}

// Order page and Pricing page pricing tables
//- - - - - - - - - - - - - - - - - - - - - - - - - - - -
.prices-chart {
  overflow-x: scroll;
}

.prices-chart__table {
  margin-bottom: 0;
  min-width: 600px;
}

//order page
.prices-chart__holder {
  @include right-side-gradient(820px);
}

//prices page
.prices-chart__holder--prices {
  @include right-side-gradient(1190px);
}

.prices-chart__label {
  margin: 10px 0;
}


