// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # Blocks
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// ## Description:
// Blocks are chucks of content that usually repeat on
// multiple pages.
//
// ## Notes:
// - prefix "b-" before each class.
//
// ## Table of Contents:
// 1. Promo
// 2. Support
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -



// 1. Promo
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Displays the current promotional sale on inner pages.

.b-promo {
  background: $b-promo-bg;
  font-style: italic;
  font-weight: 700;
  overflow: hidden;
  padding: 21px 26px 22px 25px;
}

.b-promo__title {
  color: $b-promo-text;
  font-size: 38px;
  line-height: 42px;
  margin: 0;
  text-align: center;
  text-shadow: 2px 2px $shadow-color;

  @include breakpoint(medium) {
    float: left;
    margin: 0 44px 0 0;
  }
}

.b-promo__description {
  color: $white;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  @include breakpoint(medium) {
    float: left;
    margin: 0 10px 0 0;
    text-align: right;
  }
}

.b-promo__details {
  color: $b-promo-text;
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
}

.b-promo__button {
  @include breakpoint(small only) {
    display: block;
    margin: 0 auto;
  }
  @include breakpoint(medium) {
    float: right;
    margin: 3px -2px 0;
    width: 137px;
  }
}




// Hero
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.b-hero {
  background: url('../images/hero.jpg');
  background-color: $white;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 5px solid $secondary-color;
  margin: -$grid-column-gutter;
  margin-bottom: $grid-column-gutter;
  min-height: 440px;
}

.b-hero__content {
  padding: $grid-column-gutter*2 0 $grid-column-gutter;
}

.b-hero__title {
  font-size: rem-calc(30);
  font-weight: bold;
  line-height: rem-calc(30);
  margin-bottom: rem-calc(30);
  text-shadow: 2px 2px 10px $lightest-blue;
  span { color: $success-color; }
}

.b-hero__timer {
  background: $white;
  border: 10px solid $alert-color;
  color: $alert-color;
  display: inline-block;
  font-size: rem-calc(50);
  font-weight: bold;
  margin-bottom: $grid-column-gutter;
  padding: rem-calc(5);
}

.b-hero__list {
  font-size: rem-calc(20);
}

.b-hero__button {
  font-size: rem-calc(20);
  line-height: 1;
  padding: rem-calc(15) rem-calc(25);

  @include breakpoint(medium) {
    font-size: rem-calc(25);
    padding: rem-calc(20) rem-calc(35);
  }
}


// Selling Points
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.b-selling-points {
  background: $banner-sameday;
}

// .b-selling-points__list {}

%selling-points-item {
  color: $white;
  font-size: rem-calc(18);

  & > span {
    display: block;
    padding-top: rem-calc(18);
    text-shadow: 2px 1px $white;
  }
}

.b_selling-points__item--same-day-production {
  @extend %selling-points-item;
  background: $banner-sameday;
}

.b_selling-points__item--eco-friendly-product {
  @extend %selling-points-item;
  background: $banner-eco;
}

.b_selling-points__item--made-in-usa {
  @extend %selling-points-item;
  background: $banner-usa;
}

.b_selling-points__item--rush-production {
  @extend %selling-points-item;
  background: $banner-rush;
}

.b_selling-points__item--no-minimum {
  @extend %selling-points-item;
  background: $banner-nominimum;
}
