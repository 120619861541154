// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Page (p-)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.p-page {
  background: $white;
  box-shadow: 1px 1px 5px 0 $shadow-color;
  margin: 0 auto;
  max-width: $grid-row-width;
  padding: $grid-column-gutter/2;
  position: relative;
  @include breakpoint(medium) {
    padding: $grid-column-gutter;
  }
}

.p-page--dock-bottom {
  padding-bottom: 72px;
}

.center {
  text-align: center;
}

// Title
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.p-title {
  margin-bottom: $grid-column-gutter/2;
  padding: $grid-column-gutter 0 $grid-column-gutter/4;
}

.p-title__heading {
  color: $info-color;
  font-size: rem-calc(26);
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1;

  @include breakpoint(medium) {
    font-size: rem-calc(46);
  }
}

.order.title.tag,
.instructions.message.tag {
  display: inline-block;
}

.tyvek.option.tag,
.keychain.option.tag,
.individual.bagging.option.tag,
.custom.bagging.option.tag,
.etsy.order.option.tag,
.thicker.option.tag,
.shipper.attention.option.tag {
  display: inline-block;
  height: 24px;
  padding: 0 10px;
}

.tyvek.order.option.tag {
  background-color: $orange;
  color: $ghost;
}

.custom.bagging.option.tag {
  background-color: $red;
  color: $ghost;
}

.individual.bagging.option.tag {
  background-color: $yellow;
}

.keychain.option.tag {
  background-color: $banner-eco;
}

.etsy.order.option.tag {
  background-color: $magenta;
  color: $ghost;
}

.thicker.option.tag {
  background-color: $purple;
  color: $ghost;
}

.shipper.attention.option.tag {
  background-color: $red;
  color: $ghost;
}

// Modal
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

._720kb-datepicker-calendar.test-custom-class._720kb-datepicker-open {
  position: inherit;
}

// Free Sample
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.alert .message-box,
.alert .wrong-message-box {
  border-radius: 10px;
  box-shadow: $shadow 5px 5px 5px;
  color: $black;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;
}

.alert .message-box {
  background-color: $banner-eco;
}

.alert .wrong-message-box {
  background-color: $red;
}

// login
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.button.success.ffab-before.fa-lock {
  width: 100%;
}


// Youtube Video Embed
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.video-wrapper {
  padding-top: 25px;
  position: relative;

  iframe {
    left: 0;
    top: 0;
    padding: 4px;
    float: left;
  }
}

// Fundraiser Calculator
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.fundraiser-form {
  line-height: 3;
  position: relative;

  .fundraiser-group {
    border-collapse: separate;
    display: inline-flex;
  }

  .fundraiser-addon {
    background-color: $light-gray;
    border: 1px solid $medium-gray;
    border-radius: 4px 0 0 4px;
    color: $charcoal;
    display: table;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 10px;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }

  .fundraiser-field {
    border-radius: 0 4px 4px 0;
    height: 2.4rem;
    width: auto;
  }

  .fundraiser-style-group {

    .fundraiser-field {
      border-radius: 4px;
    }
  }
}

.fundraiser-calculations {
  background-color: $light-gray;
  border: solid 1px $medium-gray;
  border-radius: 10px;
  padding: 20px;

  .fundraiser-field {
    border-radius: 4px;
    display: inline-block;
    height: 2.4rem;
    width: auto;
  }

  .band-quantity {
    font-weight: bold;
  }

  .alert {
    color: $red;
  }

  .success {
    color: $banner-eco;
  }

  .error {
    font-size: 11px;
    font-weight: bold;
  }
}

// Idea Pages
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.idea-clipart {
  padding: 10px 40px;
}

.glow-in-dark {
  background-image: url('/images/pages-assets/glow-in-dark-wristbands-light.jpg');
  background-size: contain;
  height: auto;
  margin: 0 auto;
  max-height: 500px;
  max-width: 600px;

  &:hover {
    background-image: url('/images/pages-assets/glow-in-dark-wristbands-dark.jpg');
  }
}

.ideas__button {
  margin: 0 auto;
  text-align: center;
}

// Carousel Ideas
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.ideas-slider {

  .slick-next::before,
  .slick-prev::before {
    color: $black;
    font-size: 27px;
    height: 30px;
    width: 30px;
  }

  .slick-next {
    right: -17px;
  }

  .carousel__image {
    display: inline-block;
    -moz-filter: none;
    -o-filter: none;
    -webkit-filter: none;
    filter: none; // IE6-9
    max-height: 300px;
    max-width: 28%;
    padding: 20px;
  }

  .carousel__content {
    display: inline-block;
    margin: 0 auto;
    vertical-align: middle;
    width: 70%;
  }

  .slick-list {
    display: block;
    margin: 0 auto;
    width: 90%;
  }

  .carousel__content__wrapper {
    bottom: 0;
    display: inline-block;
    left: 0;
    margin: auto;
    right: 0;
    top: 0;
    width: 100%;
  }

  blockquote {
    border-left: 0;
    font-family: $blockquote-font-family;
    font-style: italic;
    font-weight: bold;
    line-height: 1.45;
    margin: 0.25em 0;
    padding: 0.35em 40px;
    position: relative;

    &::before {
      color: $monsoon;
      content: '\201C';
      display: block;
      font-size: 80px;
      left: -20px;
      padding-left: 10px;
      position: absolute;
      top: -20px;
    }
  }

  blockquote cite {
    color: $aluminum;
    display: block;
    font-size: 14px;
    margin-top: 5px;

    &::before {
      content: '\2014 \2009';
    }
  }
}

// Wristband Styles
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.sidebar-wristbands {
  margin-top: 20px;

  .sidebar-title {
    background: $blue;
    border-radius: 5px 5px 0 0;
    color: $ghost;
    margin: -0.5rem -0.5rem 0.5rem;
    padding: 0.5rem;
  }

  .wristband-styles {
    list-style-type: none;

    li {
      margin-bottom: 5px;
    }
  }

  .menu_active {
    font-weight: bold;
  }
}

.styles-content .p-page {
  border-radius: 6px;
  margin-top: 20px;
}

// Blog List
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.blog__post__content {
  font-family: $body-font-family;
  font-size: 14px;

  img {
    max-height: 150px;
    max-width: 150px;
  }
}

.blog__post__list {

  .blog__post__element {

    list-style-type: none;
  }

  .blog__post__separator {
    margin-top: 30px;
  }
}

.blog-post-tags {
  list-style-type: none;

  li {
    display: inline-block;
    font-weight: bolder;
    margin-right: 50px;
  }
}

.pagination {
  color: $blue;
  font-size: 20px;
  text-align: center;

  .disabled,
  .active {
    color: $dark-gray;
    font-size: 20px;
  }

  a {
    color: $blue;
    font-size: 20px;
  }
}
