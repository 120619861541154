// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # Header (h-)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// Description:
// The top of the website.
//
// 0. Header
// 1. Top Menu
// 2. Info
// 3. Main Menu
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Body
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

body,
html {
  min-width: 320px;
}

// Header
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.h-header {
  background: $white;
}

.header-cart:hover {
  color: $orange;
}

// Top Menu
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.h-top-menu {
  border-top: 8px solid $h-top-menu-bg;
  display: none;
  overflow: auto;
  zoom: 1;

  @include breakpoint(medium) {
    display: block;
  }
}

.h-top-menu__list {
  background: $h-top-menu-bg;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  list-style: none;
  margin: 0;
  padding: 0 7px 5px;
  text-align: center;
  text-transform: capitalize;
  @include breakpoint(medium) {
    display: inline-block;
    float: right;
    text-align: right;
  }

  & > li {
    color: $white;
    display: inline;
    font-size: 14px;
    margin: 0;
    padding: 0 4px;
    @include breakpoint(medium) {
      padding: 0 7px 10px;
    }

    & i {
      padding-right: rem-calc(3);
    }

    & > a {
      color: $white;
      text-decoration: none;
    }
  }
}

.cart-sidebar-bg {
  display: none;
}

.cart-sidebar-show {
  display: block;
}

// Info
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Shows the Logo, phone number and ratings

.h-info {
  overflow: auto;
  padding: rem-calc(20) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.h-info__logo {
  display: block;

  & > img {
    margin-top: 5px;
  }
}

// .h-info__contact {}

.h-info__ratings {
  display: none;
  margin: 12px 5px 0 0;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  &__item {
    padding-right: 10px;
  }

  & > a:last-of-type {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

// Coupon Bar
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.coupon__bar {
  color: $ghost;
  font-size: 17px;
  padding: 6px;

  &.success {
    background-color: $success-bar;
  }

  &.warning {
    background-color: $failed-bar;
  }
}


.dropdown-pane {
  background: #ffffff;

  li {
    padding-bottom: 10px;
  }
}
