// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Banner
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.banner {
  margin: -$grid-column-gutter / 2;
  margin-bottom: 0;

  @include breakpoint(medium) {
    margin: -$grid-column-gutter;
    margin-bottom: $grid-column-gutter;
  }
}

.banner__points {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.banner__point {
  align-items: center;
  background: $black;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: rem-calc(18);
  font-weight: bold;
  height: 60px;
  padding: rem-calc(10) rem-calc(30);
  position: relative;

  @include breakpoint(medium) {
    font-size: rem-calc(25);
    height: 95px;
  }

  &.js-active {
    border-right: 5px solid $white;
  }

  &::after {
    content: '';
    //adds drop shadow in webkit browsers, other browsers will show without the shadow
    filter: drop-shadow(2px 2px 2px $shadow-color);
    height: 50px;
    position: absolute;
    right: 25px;
    top: 5px;
    width: 40px;

    @include breakpoint(medium) {
      right: 15px;
      top: 25px;
      width: 50px;
    }
  }

}

.banner__point--sameday {
  background: $banner-sameday;

  &::after {
    background: url('/images/vector-icons/same-day.png') center no-repeat;
    background: url('/images/vector-icons/same-day.svg') center no-repeat;
  }
}

.banner__point--eco {
  background: $banner-eco;

  &::after {
    background: url('/images/vector-icons/eco-friendly.png') center no-repeat;
    background: url('/images/vector-icons/eco-friendly.svg') center no-repeat;
  }
}

.banner__point--usa {
  background: $banner-usa;

  &::after {
    background: url('/images/vector-icons/usa-flag.png') center no-repeat;
    background: url('/images/vector-icons/usa-flag.svg') center no-repeat;
  }
}

.banner__point--rush {
  background: $banner-rush;

  &::after {
    background: url('/images/vector-icons/rush-shipping.png') center no-repeat;
    background: url('/images/vector-icons/rush-shipping.svg') center no-repeat;
  }
}

.banner__point--nominimum {
  background: $banner-nominimum;

  &::after {
    background: url('/images/vector-icons/just-one.png') center no-repeat;
    background: url('/images/vector-icons/just-one.svg') center no-repeat;
  }
}

.banner__point__text {
  text-shadow: 2px 2px 2px $shadow-color;
}

.banner__content__container {
  position: relative;

  @include breakpoint(medium) {
    display: block;
  }
}

.banner__text {
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;

  .line1 {
    color: $banner-black;
    font-size: 2rem;
  }

  .line2 {
    color: $banner-dark-blue;
    font-size: 4.3rem;
    font-weight: 700;
    line-height: 4rem;
  }

  .line3 {
    color: $banner-black;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .line4 {
    color: $banner-light-blue;
    font-size: 6.5rem;
    line-height: 5rem;
  }
}

.banner__content {
  background-size: cover;
  display: none;
  height: 475px;
  padding: rem-calc(40) rem-calc(25);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  &.js-active {
    display: block;
  }
}

.order_button{
  position: relative;
  top: 100%;

 text-align: center;
  height: 50px;
  right:5%;


  @include breakpoint(small only) {

    right:-5%;
  }
}

.inner_order_button
{

  height: inherit;
  width: 60%;
  float: right;

  @include breakpoint(small only) {

    float : none;
    width : auto;
  }
}



.banner__content--main {
  background: url('../../images/banner/message.jpg') center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: relative;
  overflow-y: hidden;

  @media screen and (min-width: 40em) and (max-width: 63.9375em){
    background: url('../../images/banner/message-tab.jpg') center center;
    height: 395px;
    background-size: cover;
  }

  @media screen and (max-width: 414px) and  (min-width: 414px){
    background: url('../../images/banner/message-tab.jpg') center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 185px !important;
  }


  @media screen and (max-width: 39.9375em) {
    background: url('../../images/banner/message-mobile.jpg') center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 171px;
  }


  .button-container {
    bottom: 0;
    position: absolute;
    text-align: center;

    @include breakpoint(small only) {
      margin: 10px 0 0;
    }
  }

  .banner__content__button {
    margin: 0;
  }

  .order_button_proc
  {

    position: relative;
    right: 0;
    width: 50%;
    float:right;

    @include breakpoint(medium only) {

      position: relative;
      right: 0;
      width: 60%;
      float: right;
    }
    @include breakpoint(small only) {

      position: relative;
      right: 0;
      width: 60%;
      float: right;
    }
  }
}

.banner__content__heading {
  font-size: rem-calc(50);
  font-weight: bold;
  line-height: 1;
  margin-bottom: rem-calc(30);
  text-align: right;

  span {
    color: $success-color;
  }
}
