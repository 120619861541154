// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Checkout
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.cart-sidebar-bg {
  background: $shadow-color;
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  transition: all 0.5s ease;
  width: 100%;
  z-index: 99999999;
}

.ng-hide {
  &.cart-sidebar-bg {
    opacity: 0;
  }

  .cart-sidebar {
    transform: translateX(100%);
  }
}

.cart-sidebar {
  background: $white;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(0%);
  transition: all 0.5s ease;
  width: 100%;

  @include breakpoint(medium) {
    width: 400px;
  }
}

.cart-sidebar__top {
  color: $white;
}

.cart-sidebar__main {
  padding: 15px;
}

.cart-sidebar__back {
  background: $light-gray;
  color: $black;
  padding: 14px;

  &:hover {
    background: lighten($light-gray, 5%);
    color: lighten($black, 5%);
  }
}

.cart-sidebar__checkout {
  background: $orange;
  color: $white;
  padding: 14px;
  text-align: right;

  &:hover {
    background: darken($orange, 5%);
    color: darken($white, 5%);
  }
}

.cart-sidebar__empty {
  color: $blue;
  font-size: rem-calc(20);
  margin-top: rem-calc(20);
  text-align: center;
}

.cart__heading {
  background: $purple;
  color: $white;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.cart {
  margin-bottom: 30px;
  padding: 0 15px;

  span {
    color: $purple;

    &.cart__qty--promo {
      color: $success-color;
    }
  }
}

.cart__title {
  font-size: 18px;
  font-weight: bold;
}

.cart__summary {
  font-style: italic;

  div {
    &:nth-child(2) {
      margin-left: 10px;
    }

    &:nth-child(3) {
      margin-left: 20px;
    }

    &:nth-child(4) {
      margin-left: 20px;
    }
  }
}

.cart__details {
  background: $vapor;
  border: 1px solid $smoke;
  display: none;
  margin-top: 10px;
  padding: 10px;
}


.requisition.item {
  display: none;
}

.cart__img {
  height: 50px;
}

.cart__link {
  color: $info-color;
  text-decoration: underline;

  &:hover,
  &:active {
    color: darken($info-color, 10%);
  }
}

.cart__link--remove {
  color: $alert-color;
  text-align: right;
}

.cart__subtotal {
  font-size: 22px;
  font-weight: bold;
}

.cart__discount {
  color: $success-color;
  font-size: 18px;
  font-weight: bold;
}

.cart__taxTotal {
  color: black;
  font-size: 22px;
  font-weight: normal;
}

.cart__subtotal--promo {
  color: $success-color;
  font-size: 18px;
  font-style: italic;
}

.cart__preview-modal {
  margin-top: 40px;

  .close-button {
    color: $light-blue;
  }
}
