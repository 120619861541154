.checkout-form {
  input:not([type="email"]) {
    text-transform: capitalize;
  }
}

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
.ng-submitted .ng-invalid {
  @include form-input-error($alert-color);
  & + .form-error {
    display: block;
  }
}

.payment.columns {
  padding: 0;
  text-align: center;

  .cc-pics {
    display: table-row;
    margin: 0 auto;
    width: 100%;
  }

  .tab-link {
    display: table;
    margin: 0 auto;
    min-height: 175px;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }

  .column {
    padding: 0;
  }

  .small-12.paypal-icon {
    display: table-row;
    margin: 0 auto;
    padding: 0;
    width: 100%;

    .paypal-logo {
      display: block;
      margin: 0;
      padding: 10px;
      width: 100%;
    }
  }

  .subheader {
    color: $jumbo;
    display: table-cell;
    font-size: 18px;
    line-height: 1.4;
    vertical-align: middle;
    width: 100%;
  }
}

.show-paypal-payment-method-view {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;

  .payment-method-item {
    color: $payment-grey;
    height: 50px;
    line-height: 50px;
    position: relative;

    .payment-method-icon {
      background-image: url('../resources/paypal-icon.png');
      background-repeat: no-repeat;
      background-size: 30px auto;
      display: inline-block;
      height: 30px;
      vertical-align: middle;
      width: 30px;
    }

    .payment-method-type {
      color: $payment-blue-dark;
      font-weight: 800;
      margin-left: 15px;
    }
  }

  .card-label {
    border-bottom: solid 1px $gainsboro;
    border-top: solid 1px $gainsboro;
    color: $steel;
    display: block;
    height: 70px;
    line-height: 50px;
    margin-top: -1px;
    overflow: hidden;
    padding: 8px 0;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: background-color 131ms linear;
    z-index: 1;
  }

  .payment-method-action {
    position: absolute;
    right: 5px;
    top: 13px;
    z-index: 10;
  }
}

.paypal.paypal--branded {
  background-color: $paypal-background;
  border: 0;
  border-radius: 20px;
  color: $snow;
  float: left;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 0 20px;
  text-align: center;



  .paypal__monogram-wordmark {
    background-image: url('../resources/paypal-button.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: inline-block;
    float: left;
    height: 26px;
    margin-right: 6px;
    margin-top: 6px;
    text-indent: -999em;
    width: 100px;
  }

  .paypal__text {
    color: $ghost;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-shadow: 0 -1px 0 $paypal-text;
  }

}

.cc-pics {
  margin-bottom: 15px;
  margin-top: 15px;

  img {
    margin-right: 10px;
    width: 50px;

    @include breakpoint(medium) {
      width: 61px;
    }
  }
}

.button-section {
  padding-top: 15px;
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.o-loading.checkout {
  background: $shadow-loading;
  height: 160px;
  position: relative;

  .la-line-scale-pulse-out.la-3x.o-loading__animation {
    left: 41%;
    position: absolute;
    top: 12px;
  }
}

.button.success.ffab-before.fa-lock {
  width: inherit;
}

.mcafee-tag-bottom {
  display: inline-block;
  position: absolute;
  margin-top: 3px;
}

#directions
{
  text-align:center;
}

#answer
{
  margin-left:40%;
}


