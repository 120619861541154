// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Credit Card
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.credit-card {
  position: relative;

  &.is-valid {
    .credit-card__valid,
    .credit-card__type {
      color: $success-color;
    }

    [type='text'] {
      background: lighten($success-color, 53%);
      border: 1px solid $success-color;
      color: $success-color;
    }
  }
}

.credit-card__input {
  padding-left: 35px;
  padding-right: 25px;
}

.credit-card__type {
  color: $dark-gray;
  left: 8px;
  position: absolute;
  top: 5px;
}

.credit-card__valid {
  color: $secondary-color;
  position: absolute;
  right: 8px;
  top: 5px;
}
