
#wristband_customizer {
	font-family: 'Helvetica Neue', Arial, sans-serif;
}

#wristband_customizer h3, #wristband_title {
	font-size: 24px;
	font-weight: normal;
	padding: 0;
	margin: 0 0 6px 0;
}

#wristband_customizer img {
	border: 0;
}

#color_side {
	float: left;
	width: 55%;
}

#color_group_select {
	margin-bottom: 12px;
}
#color_group_select a {
	text-decoration: underline;
	padding-right: 10px;
	color: #666;
}
#color_group_select a.current {
	color: orange;
	text-decoration: none;
}

#color_chart {
/*	width: 50%; */
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	float: left;
	height: 485px;
	height: 565px;
	overflow: scroll;
}

#color_chart span {
	cursor: pointer;
	display: inline-table;
	width: 70px;
	height: 70px;
	margin-right: 10px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 14px;
	color: #fff;
	/* text-shadow: 1px 1px 2px #000; */
	text-shadow: 0px 0px 3px #000000;
}

/* http://stackoverflow.com/questions/18485378/vertically-centering-text-within-an-inline-block */

#color_chart span p
{
	margin: 0;
	padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#color_chart span p.centerer
{
    height: 100%;
}

#wristband_preview {
	float: left;
	height: 485px;
	width: 45%;
/*	background-color: #ccc; */
	margin-top: 60px;
}

#wristband_title {
	text-align: center;
	height: 45px;
	padding-top: 5px;
	text-align: center;
	margin: 0;
}

#color_panel p {
	width: 360px;
	padding: 0;
	margin: 10px auto 0px auto;
	text-align: center;
	color: #777;
}
#color_panel p:first-child {
	margin-top: 0px;
}


/* CSSButtonGenerator.com for other buttons */
/* this button http://www.usabilitypost.com/2012/01/10/pressed-button-state-with-css3/ */

.band_buttons a {
    padding: 6px 15px;
    background: #4479BA;
    color: #FFF;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    border: solid 1px #20538D;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
.band_buttons a:hover {
    background: #356094;
    border: solid 1px #2A4E77;
    text-decoration: none;
}
.band_buttons a.btn_selected {
    -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
    background: #2E5481;
    border: solid 1px #203E5F;
}

.band_buttons {
	margin-top: 0px;
	text-align: center;
}
.band_buttons a {
	margin: 0 10px;
}
#top_btn_panel {
	display: block;
	margin-bottom: 15px;
}

#preview_panels {
}
#preview_panels div {
/*	text-align: center; */
}

.preview_header {
	padding-top: 10px;
	height: 50px;
}

.band_preview {
	height: 278px;
	position: relative;
	display: block;
	width: 387px;
	margin: 0 auto;
}
.band_preview img {
	position: absolute;
	top: 0px;
}

.color_buttons {
	clear: both;
	text-align: center;
}
.color_buttons a {
    padding: 6px 0px;
    background: #4479BA;
    background: white;
    color: #FFF;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
/*    border: solid 4px #777; */
    text-decoration: none;
/*
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
*/
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
	text-shadow: 0px 0px 3px #000000;

	box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .5);
	-moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .5);
}
.color_buttons a {
	margin-right: 20px;
	margin-top: 8px;
	width: 145px;
	display: inline-block;
}
.color_buttons a.btn_selected {
/*	border-color: orange; */
	box-shadow: inset 0px 0px 5px 3px rgba(0, 0, 0, .5);
	-moz-box-shadow: inset 0px 0px 5px 3px rgba(0, 0, 0, .5);
	-webkit-box-shadow: inset 0px 0px 5px 3px rgba(0, 0, 0, .5);
}
.color_buttons a:hover, .color_buttons a.map_hover {
	color: orange;
	box-shadow: 0px 0px 5px 3px rgba(242,169,0, .5);
	-moz-box-shadow: 0px 0px 5px 3px rgba(242,169,0, .5);
	-webkit-box-shadow: 0px 0px 5px 3px rgba(242,169,0, .5);
}
