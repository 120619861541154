// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Noun Project
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.o-clipart-results {
  list-style-type: none;
  margin: 0;

  li {
    margin-right: 12px;
    margin-bottom: 12px;
    display: inline-block;
  }

  img {
    width: 50px;
  }
}

.clipart {}

.clipart__inputarea {
  position: relative;
}

.clipart__input {
  border-radius: $global-radius 0 0 $global-radius;
}

.clipart__clear {
  position: absolute;
  top: 6px;
  right: 8px;
  color: #ccc;
  &:hover {
    color: $black;
  }
}

.button.clipart__submit {
  border-radius: 0 $global-radius $global-radius 0;
}


.clipart__heading {
  font-size: rem-calc(30);
  font-weight: bold;
  margin-top: rem-calc(15);
  line-height: 1;
}

.clipart__count {
  margin-bottom: rem-calc(18);
}

.clipart__results {}

.clipart__noresults {
  margin: 0 0.625rem;
}

.clipart__box {
  border: 3px dashed #ccc;
  padding: 10px;
  min-height: 76px;
  text-align: center;
  display: inline-block;

  &:hover {
    cursor: pointer;
    border-color: $info-color;
    background: lighten($info-color,40%);
  }
  &.js-active {
    border-color: $success-color;
    background: lighten($success-color,40%);
  }
}

.clipart__loading {
  @extend .fa;
  @extend .fa-spin;
  @extend .fa-refresh;
  font-size: 50px;
  margin-top: 10px;
  color: #ccc;
}

.clipart__img {}

.clipart__word {
  // background: $info-color;
  // color: $white;
  // padding: 1px 7px;
  // border-radius: $global-radius*4;
  // margin-left: 10px;
  // cursor: pointer;
}
